<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>控制中心</b-breadcrumb-item>
            <b-breadcrumb-item active>每日活動管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="mb-2 mb-xl-0 font-weight-bold">每日活動管理</h4>
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.DAILY_ACT_LIST_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'DailyActivityCreate' }"
            ><i class="fa fa-plus"></i>新增每日活動</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="dailyActivities"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'DailyActivityView',
                      params: { activity_id: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.DAILY_ACT_LIST_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'DailyActivityEdit',
                      params: { activity_id: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.DAILY_ACT_LIST_MODIFY])"
                    variant="inverse-danger"
                    @click="deleteDailyActivity(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dailyActivityApi from "@/apis/daily-activity";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { format } from "date-fns";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { paginationMixin } from "@/mixins/pagination";

export default {
  components: {
    CustomPagination,
  },
  mixins: [paginationMixin],
  data() {
    return {
      consts: consts,
      totalRows: 0,
      showLoading: true,
      dailyActivities: [],
      dailyActivity: null,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
      },
      fields: [
        {
          key: "activity_code",
          label: "每日活動代碼",
        },
        {
          key: "activity_at",
          label: "活動時間",
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd");
          },
        },
        {
          key: "created_at",
          label: "建立時間",
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
          },
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.getDailyActivities();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getDailyActivities();
      this.initialized = true;
    },
    async getDailyActivities() {
      this.showLoading = true;
      try {
        let params = {
          ...this.query,
        };

        const { data } = await dailyActivityApi.getDailyActivities(
          this.organization,
          params
        );
        this.dailyActivities = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得每日活動資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteDailyActivity(dailyActivity) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>每日活動：${dailyActivity.activity_code}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await dailyActivityApi.removeDailyActivity(
              this.organization,
              dailyActivity
            );
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 ",
              });
              this.getDailyActivities();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
